export function parseTheme(origin) {
  const parsedTheme = {
    ...origin,
    tema: JSON.parse(origin.tema),
  };
  return parsedTheme;
}

export const getDomain = (host) => {
  if (host === "app.antecipafacil.com.br") {
    return "antecipafacil.com.br";
  } else if (host === "app.federalinvestfacil.com.br") {
    return "federalinvestfacil.com.br";
  } else if (host === "app.afcash.com.br") {
    return "afcash.com.br";
  } else if (host === "app.coneantecipa.com.br") {
    return "coneantecipa.com.br";
  } else if (host === "app.invent.4money.io") {
    return "invent.4money.io";
  } else if (host === "app.antecipa.4money.io") {
    return "antecipa.4money.io";
  } else if (host === "hmg.antecipafacil.com.br") {
    return "hmg-onboarding.antecipafacil.com.br";
  } else if (host === "app.sejanitro.com.br") {
    return "sejanitro.com.br";
  } else if (host === "cessao.libercapital.com.br") {
    return "cadastro.cessao.libercapital.com.br";
  } else if (host === "app.antecipa-cnnilog.com.br") {
    return "cadastro.antecipa-cnnilog.com.br";
  } else if (host === "app.giromaiscapital.com.br") {
    return "cadastro.app.giromaiscapital.com.br";
  } else if (host === "app-restrict.antecipafacil.com.br") {
    return "cadastro-restrict.antecipafacil.com.br";
  }

  const parts = host.split(".");

  const unformattedDomain = parts.slice(-4);

  return unformattedDomain?.join(".");
};

export const getRedirectUrl = () => {
  const urls = process.env.REACT_APP_REDIRECT_URLS?.replace(" ", "").split(",");

  const hostname = window.location.hostname;
  const domain = getDomain(hostname);

  const redirectUrl = urls?.find((url) => url?.includes(domain));

  return redirectUrl || "https://cadastro.antecipafacil.com.br";
};

export const getAuthDomain = (hostname) => {
  if (hostname.includes("credpj.com.br")) {
    return hostname;
  } else if (hostname.includes("federalinvestfacil")) {
    return ".federalinvestfacil.com.br";
  } else if (hostname.includes("afcash")) {
    return ".afcash.com.br";
  } else if (hostname.includes("coneantecipa")) {
    return ".coneantecipa.com.br";
  } else if (hostname.includes("antecipa.4money.io")) {
    return ".antecipa.4money.io";
  } else if (hostname.includes("invent.4money.io")) {
    return ".invent.4money.io";
  } else if (hostname.includes("sejanitro.com.br")) {
    return ".sejanitro.com.br";
  }

  return ".antecipafacil.com.br";
};
